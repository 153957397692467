exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paintings-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/paintings/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-paintings-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-wilderness-js": () => import("./../../../src/pages/wilderness.js" /* webpackChunkName: "component---src-pages-wilderness-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

